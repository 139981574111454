import React from 'react';
import { Grid } from '@material-ui/core';
import SmallCard from '../../Cards/SmallCard/SmallCard';
import HeroCard from '../../Cards/HeroCard/HeroCard';
import PageTitleCard from '../../Cards/PageTitleCard/PageTitleCard';

const Layout = ({ postsData = false }) => {
    let otherCardData = [];
    let heroCardData = {};

    if (postsData) {
        heroCardData = {
            img: postsData[0].lgFluid,
            title: postsData[0].title,
            text: postsData[0].excerpt_long,
            date: postsData[0].date,
            split: true,
            linkText: 'Lasīt tālāk...',
            linkHref: postsData[0].slug,
            themes: postsData[0].themes,
            allPostsUrl: '/' + postsData[0].category + '/all',
        };

        const otherPosts = postsData.slice(1);
        otherCardData = otherPosts.map((data) => ({
            img: data.smFluid,
            title: data.title,
            text: data.excerpt_short,
            date: data.date,
            linkText: 'Lasīt tālāk...',
            linkHref: data.slug,
            themes: data.themes,
        }));
    } else {
        console.log('CommonPageLayout: no postsData received!');
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ width: '100%' }}>
                <HeroCard
                    title={heroCardData.title}
                    subTitle={heroCardData.subTitle}
                    text={heroCardData.text}
                    date={heroCardData.date}
                    img={heroCardData.img}
                    linkText={heroCardData.linkText}
                    linkHref={heroCardData.linkHref}
                    themes={heroCardData.themes}
                    split={heroCardData.split}
                />
            </Grid>
            {otherCardData.map((el, idx) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={idx} style={{ width: '100%' }}>
                    <SmallCard
                        title={el.title}
                        date={el.date}
                        img={el.img}
                        text={el.text}
                        linkText={el.linkText}
                        linkHref={el.linkHref}
                        themes={el.themes}
                    />
                </Grid>
            ))}
            <Grid item xs={12} style={{ width: '100%' }}>
                <PageTitleCard label="" linkText="VISI RAKSTI" href={heroCardData.allPostsUrl} />
            </Grid>
        </Grid>
    );
};

export default Layout;
