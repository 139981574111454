import { useStaticQuery, graphql } from 'gatsby';
import { spreadResult } from './fragments/latestPostsCommonFragment';

const useLatestRecipePosts = () => {
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            query latestRecipes {
                allMarkdownRemark(
                    filter: { frontmatter: { category: { eq: "recipes" } } }
                    sort: { order: DESC, fields: frontmatter___date }
                    limit: 7
                ) {
                    ...latestPostsCommon
                }
            }
        `,
    );

    let postsData = spreadResult(allMarkdownRemark);
    return postsData;
};

export default useLatestRecipePosts;
